import React from "react";

const Facilities = () => {
  return (
    <div className="page-content-wrapper sm-top service-details-wrapper bs100 section-facilities">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <img
              src={require("../../assets/img/facilities-kc.jpg")}
              alt="Kangaroocare-Women and children Hospital waiting area"
              className="facimg ts50"
            />
          </div>
          <div className="col-md-6 col-xs-12 service-details-info">
            <ul>
              <li>
                Highly qualified doctors, experienced paramedics, friendly
                nurses, and caring staff members.
              </li>
              <li>
                Round the clock Paediatric and OBG consultation with 24 hrs
                working in-house pharmacy.
              </li>
              <li>
                Level 3B N.I.C.U. and OTs laden with the latest technology and
                good anesthetist backup.
              </li>
              <li>24 hours functioning laboratory support.</li>
              <li>
                Round the clock well-equipped emergency services for Women and
                Children.
              </li>
              <li>
                Centrally air-conditioned rooms to make your visit and stay
                comfortable
              </li>
              <li>
                The twin and triple sharing rooms are the least expensive.
              </li>
              <li>
                Luxury single occupancy rooms with signature suites available.
              </li>
              <li>Excellent infrastructure and equipment.</li>
              <li>
                Experienced dieticians and physiotherapist available for
                consultation.
              </li>
              <li>Retrieval ambulance.</li>
              <li>
                Cafeteria, coffee shop, florist, photo studio and baby shop.
              </li>
              <li>Labour, Delivery and Recovery room.</li>
              <li>Nurse call system in all rooms including the restroom.</li>
              <li>Antenatal classes (baby shower), yoga, Lamaze.</li>
              <li>Postnatal lactation classes.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
